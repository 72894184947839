import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';

import { AppContext } from '../ContextProvider';


const NavBar = () => {
  const { actions, state } = useContext(AppContext);
  const { logout } = actions;
  const { appSettings } = state;

  return (
    <div className="navbar">
      <ul className="nav">
        <li className="nav-item">
          <NavLink exact to="/products" className="nav-link" activeClassName="active">
            Products
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact to="/ingredients" className="nav-link" activeClassName="active">
            Ingredients
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink exact to="/ingredient_groups" className="nav-link" activeClassName="active">
            Ingredient Groups
          </NavLink>
        </li>
      </ul>
      <GoogleLogout
        clientId={appSettings.googleClientId}
        render={renderProps => (
          <button className="button-logout" onClick={renderProps.onClick} >LOGOUT</button>
        )}
        buttonText="Logout"
        onLogoutSuccess={logout}
        onFailure={logout}
        hostedDomain="selectivehumans.com"
      />
    </div>
  )
};

export default NavBar;
