import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AppContext } from './ContextProvider';
import Header from './elements/Header';
import Footer from './elements/Footer';


const PrivateRoute = props => {
  const { component: Component, ...rest } = props;
  const { state } = useContext(AppContext);
  const { user } = state;

  return (
    <Route
      {...rest}
      render={props =>
        user.loggedIn
          ? <div className="app">
            <Header />
            <div className="content">
              <Component {...props} />
            </div>
            <Footer />
          </div>
          : <Redirect to={{ pathname: '/' }} />
      }
    />
  );
};

export default PrivateRoute;
