import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../ContextProvider';
import EditContent from '../elements/EditContent';
import RelationsManager from './RelationsManager';
import RelationsManagerItemRenderer from './RelationsManagerItemRenderer';


const Product = props => {
  const { actions } = useContext(AppContext);
  const { updateProduct, deleteProduct } = actions;
  const { product } = props;

  const [isReleased, setIsReleased] = useState(product.released);

  const handleChange = e => {
    setIsReleased(e.target.checked);
    product.released = e.target.checked;
    updateProduct({ product });
  };

  useEffect(() => setIsReleased(product.released), [product]);

  return (
    <div className="product-details">
      <div>
        <EditContent
          item={product}
          updateMethod={updateProduct}
          dbColumn="product_name"
          identifier="product_id"
          placeholder="Product Name"
        />
        &nbsp;
        <small className="article-nr">
          ART NR.
          &nbsp;
          <EditContent
            item={product}
            updateMethod={updateProduct}
            dbColumn="article_nr"
            identifier="product_id"
            placeholder="Article Number"
          />
        </small>
      </div>
      <div>
        Description:&nbsp;
        <EditContent
          item={product}
          updateMethod={updateProduct}
          dbColumn="product_description"
          identifier="product_id"
          placeholder="Description"
        />
      </div>
      <div>
        Manufacturer:&nbsp;
        <EditContent
          item={product}
          updateMethod={updateProduct}
          dbColumn="product_manufacturer"
          identifier="product_id"
          placeholder="Manufacturer"
        />
      </div>
      <div>
        Image:&nbsp;
        <EditContent
          item={product}
          updateMethod={updateProduct}
          dbColumn="product_image"
          identifier="product_id"
          placeholder="Image"
        />
      </div>
      <div>
        <label htmlFor="released">Released:</label>&nbsp;
        <input
          onChange={e => handleChange(e)}
          checked={isReleased}
          id="released"
          name="released"
          type="checkbox"
        />
      </div>

      <RelationsManager
        currentItem={product}
        updateFunction={updateProduct}
        searchItems="ingredients"
        searchItemKey="product"
        searchItemIdentifier="product_id"
        relationName="ingredient_name"
        relationChildren="ingredients"
        relationIdentifier="ingredient_id"
        relationDescription="ingredient_description"
        currentItemsTitle="Ingredients"
        searchLabel="Search ingredients"
        itemRenderer={item => <RelationsManagerItemRenderer item={item} />}
        currentItemRenderer={item => <RelationsManagerItemRenderer item={item} />}
      />

      <div className="metadata">
        Added by <strong>{product._created_by_user}</strong> ({new Date(product._created).toUTCString()})
        <br />
        Modified by <strong>{product._modified_by_user}</strong> ({new Date(product._modified).toUTCString()})
      </div>

      <div>
        <button onClick={() =>
          window.confirm(`Are you sure you want to delete product "${product.product_name}"?`) && deleteProduct(product)
        }>
          Delete product
        </button>
      </div>
    </div>
  )
};

export default Product;
