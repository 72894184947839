import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../ContextProvider';
import AddIngredient from '../elements/AddIngredient';
import Ingredient from '../elements/Ingredient';
import IngredientsList from '../elements/IngredientsList';
import Message from '../elements/Message';


const Ingredients = props => {
  const { state } = useContext(AppContext);
  const { ingredients, messages } = state;
  const { id } = props.match.params;

  const currentIngredient = ingredients.find(i => i.ingredient_id === id);
  const [selectedIngredient, setSelectedIngredient] = useState(currentIngredient);

  useEffect(() => {
    setSelectedIngredient(currentIngredient);
  }, [id, currentIngredient]);

  return (
    <div className="ingredients">
      <div>
        <Link to="/ingredients/add">
          <button>Add ingredient</button>
        </Link>
        <IngredientsList ingredients={ingredients} />
      </div>

      {id === 'add'
        ? <AddIngredient />
        : selectedIngredient && <Ingredient ingredient={selectedIngredient} />
      }

      {messages.DELETE_INGREDIENT &&
        <Message message={<>Ingredient successfully deleted.</>}/>
      }
    </div >
  )
};

export default Ingredients;
