import React, {useContext, useEffect, useState} from 'react';

import { AppContext } from '../ContextProvider';
import EditContent from './EditContent';
import RelationsManager from './RelationsManager';


const Ingredient = props => {
  const { actions } = useContext(AppContext);
  const { updateIngredient, deleteIngredient } = actions;
  const { ingredient } = props;

  const [isAllergen, setIsAllergen] = useState(ingredient.ingredient_is_allergen);
  const [isOrganic, setIsOrganic] = useState(ingredient.ingredient_is_organic);

  const handleChange = e => {
    if (e.target.id === 'allergen') {
      setIsAllergen(e.target.checked);
      ingredient.ingredient_is_allergen = e.target.checked;
    }
    if (e.target.id === 'organic') {
      setIsOrganic(e.target.checked);
      ingredient.ingredient_is_organic = e.target.checked;
    }
    updateIngredient({ ingredient });
  };

  useEffect(() => {
    setIsAllergen(ingredient.ingredient_is_allergen);
    setIsOrganic(ingredient.ingredient_is_organic);
  }, [ingredient]);

  return (
    <div className="ingredient-details">
      <div>
        Name:&nbsp;
        <EditContent
          item={ingredient}
          updateMethod={updateIngredient}
          dbColumn="ingredient_name"
          identifier="ingredient_id"
          placeholder="Ingredient Name"
        />
      </div>
      <div>
        Description:&nbsp;
        <EditContent
          item={ingredient}
          updateMethod={updateIngredient}
          dbColumn="ingredient_description"
          identifier="ingredient_id"
          placeholder="Description"
        />
      </div>
      <div>
        <label htmlFor="allergen">Allergen:</label>&nbsp;
        <input
          onChange={e => handleChange(e)}
          checked={isAllergen}
          id="allergen"
          name="allergen"
          type="checkbox"
        />
      </div>
      <div>
        <label htmlFor="organic">Organic:</label>&nbsp;
        <input
          onChange={e => handleChange(e)}
          checked={isOrganic}
          id="organic"
          name="organic"
          type="checkbox"
        />
      </div>

      <RelationsManager
        currentItem={ingredient}
        updateFunction={updateIngredient}
        searchItems="ingredients"
        searchItemKey="ingredient"
        searchItemIdentifier="ingredient_id"
        relationName="ingredient_name"
        relationChildren="ingredient_synonyms"
        relationIdentifier="ingredient_id"
        relationIdentifierOverride="ingredient_synonym_id"
        relationDescription="ingredient_description"
        currentItemsTitle="Current synonyms"
        searchLabel="Add synonym(s) to this ingredient"
        searchPlaceholder="Search ingredients"
      />

      <RelationsManager
        currentItem={ingredient}
        updateFunction={updateIngredient}
        searchItems="ingredientGroups"
        searchItemKey="ingredient"
        searchItemIdentifier="ingredient_id"
        relationName="ingredient_group_name"
        relationChildren="ingredient_groups"
        relationIdentifier="ingredient_group_id"
        relationDescription="ingredient_group_description"
        currentItemsTitle="Groups"
        searchLabel="Search groups"
      />

      <div className="metadata">
        Added by <strong>{ingredient._created_by_user}</strong> ({new Date(ingredient._created).toUTCString()})
        <br />
        Modified by <strong>{ingredient._modified_by_user}</strong> ({new Date(ingredient._modified).toUTCString()})
      </div>

      <div>
        <button onClick={() =>
          window.confirm(`Are you sure you want to delete ingredient "${ingredient.ingredient_name}"?`) &&
          deleteIngredient(ingredient)
        }>
          Delete ingredient
        </button>
      </div>
    </div>
  )
};

export default Ingredient;
