import React from 'react';


const Message = props => {

  return (
    <div className="message">
      {props.message}
    </div>
  )
};

export default Message;
