import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../ContextProvider';
import AddIngredientGroup from '../elements/AddIngredientGroup';
import IngredientGroup from '../elements/IngredientGroup';
import IngredientGroupsList from '../elements/IngredientGroupsList';
import Message from '../elements/Message';


const IngredientGroups = props => {
  const { state } = useContext(AppContext);
  const { ingredientGroups, messages } = state;
  const { id } = props.match.params;

  const currentIngredientGroup = ingredientGroups.find(i => i.ingredient_group_id === id);
  const [selectedIngredientGroup, setSelectedIngredientGroup] = useState(currentIngredientGroup);

  useEffect(() => {
    setSelectedIngredientGroup(currentIngredientGroup);
  }, [id, currentIngredientGroup]);

  return (
    <div className="ingredient-groups">
      <div>
        <Link to="/ingredient_groups/add">
          <button>Add ingredient group</button>
        </Link>
        <IngredientGroupsList ingredientGroups={ingredientGroups} />
      </div>

      {id === 'add'
        ? <AddIngredientGroup />
        : selectedIngredientGroup && <IngredientGroup ingredientGroup={selectedIngredientGroup} />
      }

      {messages.DELETE_INGREDIENT_GROUP &&
        <Message message={<>Ingredient group successfully deleted.</>}/>
      }
    </div >
  )
};

export default IngredientGroups;
