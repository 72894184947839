import { useEffect, useState } from 'react';


export const useFormInput = init => {
  const [value, setValue] = useState(init);
  const [checked, setChecked] = useState(init);
  const onChange = e => {
    switch (e.target.type) {
      case 'checkbox':
        setChecked(e.target.checked);
        break;
      default:
        setValue(e.target.value);
        break;
    }
  }
  const reset = () => {
    setValue('');
    setChecked(false);
  }
  return { bind: { value, onChange, checked }, checked, reset, setValue, value };
};

export const useEffectOnce = fn => useEffect(fn, []);
