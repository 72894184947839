import React, { useContext } from 'react';

import { AppContext } from '../ContextProvider';


const Dashboard = () => {
  const { state } = useContext(AppContext);
  const { products } = state;

  return (
    <div>
      <h1>Welcome!</h1>
      <div className="info">
        <div>
          There are {products.length} products in database.
        </div>
      </div>
    </div>
  )
};

export default Dashboard;
