import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import socketIOClient from 'socket.io-client';

import useAppState from './useAppState';
import ApiHelper from '../helpers/ApiHelper';
import { useEffectOnce } from '../helpers/Hooks';


export const AppContext = React.createContext();

const AppContextProvider = props => {
  const [state, dispatch] = useAppState();
  const Api = new ApiHelper({ state });

  const initSocket = () => {
    const io = socketIOClient(state.appSettings.apiURL);
    io.on('connect', () => io.emit('getAllData'));
    io.on('products', products => dispatch({ type: 'UPDATE_PRODUCTS', products }));
    io.on('ingredients', ingredients => dispatch({ type: 'UPDATE_INGREDIENTS', ingredients }));
    io.on('ingredient_groups', ingredientGroups => dispatch({ type: 'UPDATE_INGREDIENT_GROUPS', ingredientGroups }));
  };

  // Check if user is already logged in
  useEffectOnce(() => {
    const { location } = props;
    if (location.pathname !== '/') dispatch({ type: 'REDIRECT_TO_REFERRER', pathname: location.pathname });
    Api.authenticate()
      .then(res => {
        if (res.status === 'OK') {
          dispatch({ type: 'USER_LOGGED_IN', loggedIn: true });
          initSocket();
          props.history.replace(location.pathname);
        }
      })
      .catch(e => {
        if (e.response && e.response.status === 401) {
          // dispatch({ type: 'USER_UNAUTHORIZED', res: e });
        }
      })
      .finally(() => dispatch({ type: 'APP_LOADED', appLoaded: true }));
  });

  const login = data => {
    Api.login(data)
      .then(res => {
        if (res.status === 'OK') {
          dispatch({ type: 'USER_LOGGED_IN', loggedIn: true });
          dispatch({ type: 'REDIRECT_TO_REFERRER', pathname: null });
          initSocket();
          props.history.replace(state.appSettings.redirectToReferrer || '/dashboard');
        }
      });
  };

  const logout = () => {
    Api.logout()
      .then(res => {
        dispatch({ type: 'USER_LOGGED_IN', loggedIn: false });
      });
  };

  const addIngredient = ingredient => {
    Api.addIngredient(ingredient)
      .then(res => {
        dispatch({ type: 'ADD_INGREDIENT', res });
      });
  };

  const addIngredientGroup = ingredientGroup => {
    Api.addIngredientGroup(ingredientGroup)
      .then(res => {
        dispatch({ type: 'ADD_INGREDIENT_GROUP', res });
      });
  };

  const addProduct = product => {
    Api.addProduct(product)
      .then(res => {
        dispatch({ type: 'ADD_PRODUCT', res });
      });
  };

  const deleteIngredient = ingredient => {
    Api.deleteIngredient(ingredient)
      .then(res => {
        dispatch({ type: 'DELETE_INGREDIENT', res });
      });
  };

  const deleteIngredientGroup = ingredientGroup => {
    Api.deleteIngredientGroup(ingredientGroup)
      .then(res => {
        dispatch({ type: 'DELETE_INGREDIENT_GROUP', res });
      });
  };

  const deleteProduct = product => {
    Api.deleteProduct(product)
      .then(res => {
        dispatch({ type: 'DELETE_PRODUCT', res });
      });
  };

  const updateIngredient = ingredient => {
    Api.updateIngredient(ingredient)
      .then(() => {
        dispatch({ type: 'UPDATE_INGREDIENT', ingredient });
      });
  };

  const updateIngredientGroup = ingredientGroup => {
    Api.updateIngredientGroup(ingredientGroup)
      .then(() => {
        dispatch({ type: 'UPDATE_INGREDIENT_GROUP', ingredientGroup });
      });
  };

  const updateProduct = product => {
    Api.updateProduct(product)
      .then(() => {
        dispatch({ type: 'UPDATE_PRODUCT', product });
      });
  };

  useEffect(() => dispatch({ type: 'CLEAR_MESSAGES' }), [props.location]);

  const actions = {
    addIngredient,
    addIngredientGroup,
    addProduct,
    deleteIngredient,
    deleteIngredientGroup,
    deleteProduct,
    login,
    logout,
    updateIngredient,
    updateIngredientGroup,
    updateProduct,
  };

  return (
    <AppContext.Provider value={{ state, actions }}>
      {state.appSettings.appLoaded && props.children}
    </AppContext.Provider>
  )
};

export default withRouter(AppContextProvider);
