import React from 'react';
import { Link } from 'react-router-dom';


const ProductList = props => {
  const { products } = props;

  return (
    <ul className="products-list">
      {products.map(product =>
        <li key={product.product_id}>
          <div>
            <Link to={`/products/${product.product_id}`}>{product.product_name}</Link>
            <span className="article-nr"> ({product.article_nr})</span>
          </div>
        </li>
      )}
    </ul>
  )
};

export default ProductList;
