import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../ContextProvider';
import Message from './Message';
import { useFormInput } from '../../helpers/Hooks';


const AddIngredientGroup = () => {
  const { actions, state } = useContext(AppContext);
  const { addIngredientGroup } = actions;
  const { ingredientGroups, messages } = state;
  const {
    value: ingredientGroupName,
    bind: bindIngredientGroupName,
    reset: resetIngredientGroupName,
  } = useFormInput('');
  const {
    value: ingredientGroupDescription,
    bind: bindIngredientGroupDescription,
    reset: resetIngredientGroupDescription,
  } = useFormInput('');

  const handleChange = e => {
    e.preventDefault();
    const newIngredientGroup = { ingredient_group_name: ingredientGroupName };
    if (ingredientGroupDescription && ingredientGroupDescription !== '') {
      newIngredientGroup.ingredient_group_description = ingredientGroupDescription
    }

    addIngredientGroup(newIngredientGroup);
    resetIngredientGroupName();
    resetIngredientGroupDescription();
  };

  return (
    <div className="add-ingredient-group">
      <form onSubmit={e => handleChange(e)}>
        <div>
          Name:&nbsp;
          <input
            {...bindIngredientGroupName}
            placeholder="Ingredient Group Name"
            name="ingredient_group_name"
            type="text"
            minLength={1}
          />
        </div>
        <div>
          Description:&nbsp;
          <input
            {...bindIngredientGroupDescription}
            placeholder="Ingredient Group Description"
            name="ingredient_group_description"
            type="text"
            minLength={1}
          />
        </div>

        <div>
          <button type="submit">Add Ingredient Group</button>
        </div>
      </form>

      {messages.ADD_INGREDIENT_GROUP &&
        <Message
          message={
            <>
              Successfully added <Link to={`/ingredient_groups/${messages.ADD_INGREDIENT_GROUP.message}`}>
                {ingredientGroups
                  .find(i => i.ingredient_group_id === messages.ADD_INGREDIENT_GROUP.message.toString())
                  .ingredient_group_name
                }
              </Link>.
            </>
          }
        />
      }
    </div>
  )
};

export default AddIngredientGroup;
