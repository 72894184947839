import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      v{process.env.REACT_APP_VERSION}
    </div>
  )
};

export default Footer;
