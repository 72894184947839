import React from 'react';


const RelationsManagerItemRenderer = ({ item }) =>
  <>
    <div className="item-name">
      {item.ingredient_name}
      {item.ingredient_amount &&
      ` (${item.ingredient_amount}${item.ingredient_amount_unit ? item.ingredient_amount_unit : ''})`
      }
      {item.ingredient_description &&
      <div className="item-description">({item.ingredient_description})</div>
      }
      {item.ingredient_groups.length > 0 &&
      <div className="item-groups">
        Groups:
        {item.ingredient_groups.map(group =>
          <span key={`item-group${group.ingredient_group_id}`} className="item-group">
            {group.ingredient_group_name}
          </span>
        )}
      </div>
      }
    </div>
  </>

export default RelationsManagerItemRenderer;
