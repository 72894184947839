import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../ContextProvider';
import Message from './Message';
import RelationsManager from './RelationsManager';
import RelationsManagerItemRenderer from './RelationsManagerItemRenderer';
import { useFormInput } from '../../helpers/Hooks';


const AddProduct = () => {
  const { actions, state } = useContext(AppContext);
  const { products, messages } = state;
  const { addProduct } = actions;

  const [selectedIngredients, setSelectedIngredients] = useState([]);

  const { value: productName, bind: bindProductName, reset: resetProductName } = useFormInput(null);
  const { value: articleNr, bind: bindArticleNr, reset: resetArticleNr } = useFormInput(null);
  const { value: description, bind: bindDescription, reset: resetDescription } = useFormInput(null);
  const { value: image, bind: bindImage, reset: resetImage } = useFormInput(null);

  const handleSubmit = e => {
    e.preventDefault();
    const newProduct = { product_name: productName, article_nr: articleNr };
    if (description && description !== '') newProduct.product_description = description;
    if (image && image !== '') newProduct.product_image = image;
    if (selectedIngredients && selectedIngredients.length > 0) newProduct.ingredients = selectedIngredients;

    addProduct(newProduct);
    resetProductName();
    resetArticleNr();
    resetDescription();
    resetImage();
    setSelectedIngredients([]);
  };

  const handleUpdatedItems = items => setSelectedIngredients(items);

  return (
    <div className="add-product">
      <form onSubmit={e => handleSubmit(e)}>
        <div>
          Name:&nbsp;
          <input
            {...bindProductName}
            placeholder="Product name"
            name="product_name"
            type="text"
            minLength={1}
          />
        </div>
        <div>
          Article Number:&nbsp;
          <input
            {...bindArticleNr}
            placeholder="Article Nr"
            name="article_nr"
            type="text"
            minLength={1}
          />
        </div>
        <div>
          Description:&nbsp;
          <input
            {...bindDescription}
            placeholder="Description"
            name="description"
            type="text"
            minLength={1}
          />
        </div>
        <div>
          Image:&nbsp;
          <input
            {...bindImage}
            placeholder="Image"
            name="image"
            type="text"
            minLength={1}
          />
        </div>

        <RelationsManager
          currentItem={{ ingredients: [] }}
          searchItems="ingredients"
          searchItemKey="product"
          searchItemIdentifier="product_id"
          relationName="ingredient_name"
          relationChildren="ingredients"
          relationIdentifier="ingredient_id"
          relationDescription="ingredient_description"
          currentItemsTitle="Add ingredients to product"
          searchLabel="Search ingredients"
          updateItemsFunction={items => handleUpdatedItems(items)}
          itemRenderer={item => <RelationsManagerItemRenderer item={item} />}
          currentItemRenderer={item => <RelationsManagerItemRenderer item={item} />}
        />

        <div>
          <button type="submit">Add Product</button>
        </div>
      </form>

      {messages.ADD_PRODUCT &&
        <Message
          message={
            <>
              Successfully added <Link to={`/products/${messages.ADD_PRODUCT.message}`}>
              {products
                .find(i => i.product_id === messages.ADD_PRODUCT.message.toString())
                .product_name
              }
            </Link>.
            </>
          }
        />
      }
    </div>
  )
};

export default AddProduct;
