import React from 'react';


const EditContent = props => {
  const { dbColumn, identifier, item, placeholder, updateMethod } = props;

  const handleChange = e => {
    if (e.type === 'keypress' && e.charCode === 13) e.target.blur();
    if (e.type === 'blur') {
      updateMethod({
        [dbColumn]: e.target.textContent,
        [identifier]: item[identifier],
      });
    }
  };

  return (
    <span
      contentEditable
      suppressContentEditableWarning={true}
      onBlur={e => handleChange(e)}
      onKeyPress={e => handleChange(e)}
      data-placeholder={placeholder}
    >
      {item[dbColumn]}
    </span>
  )
};

export default EditContent;
