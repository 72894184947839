import React, { useContext } from 'react';
import { GoogleLogin } from 'react-google-login';

import { AppContext } from '../ContextProvider';


const Login = props => {
  const { actions, state } = useContext(AppContext);
  const { login } = actions;
  const { appSettings, messages, user } = state;

  if (user.loggedIn) props.history.push('/dashboard');

  return (
    <div className="login">
      <h2>Selective Humans Admin</h2>
      <GoogleLogin
        clientId={appSettings.googleClientId}
        buttonText="Login"
        onSuccess={response => login(response)}
        onFailure={response => login(response)}
        cookiePolicy={'single_host_origin'}
        hostedDomain="selectivehumans.com"
        render={renderProps => (
          <button className="button-login" onClick={renderProps.onClick} >LOGIN</button>
        )}
      />

      {messages.USER_UNAUTHORIZED &&
        <div>
          Unauthorized access. Please try to log in again or contact the administrator!
        </div>
      }
    </div>
  );
};

export default Login;
