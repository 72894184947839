import { useReducer } from 'react';


const initialState = {
  appSettings: {
    apiURL: process.env.NODE_ENV === 'development' ? 'http://localhost:1908' : 'https://api.selectivehumans.com',
    appLoaded: false,
    googleClientId: '922924990775-8b5g0cjv7vep70c8ehp80pv1ei32705q.apps.googleusercontent.com',
    redirectToReferrer: null,
  },
  user: {
    loggedIn: false,
  },
  products: [],
  ingredients: [],
  ingredientGroups: [],
  messages: {},
};

const reducer = (state, action) => {
  const {
    appLoaded,
    ingredient,
    ingredients,
    ingredientGroup,
    ingredientGroups,
    pathname,
    product,
    products,
    loggedIn,
  } = action;
  const currentProduct = product
    ? state.products.findIndex(p => p.product_id === product.product_id)
    : null;
  const currentIngredient = ingredient
    ? state.ingredients.findIndex(i => i.ingredient_id === ingredient.ingredient_id)
    : null;
  const currentIngredientGroup = ingredientGroup
    ? state.ingredientGroups.findIndex(g => g.ingredient_group_id === ingredientGroup.ingredient_group_id)
    : null;

  switch (action.type) {
    case 'APP_LOADED':
      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          appLoaded,
        },
      };
    case 'ADD_INGREDIENT':
    case 'ADD_INGREDIENT_GROUP':
    case 'ADD_PRODUCT':
    case 'DELETE_INGREDIENT':
    case 'DELETE_INGREDIENT_GROUP':
    case 'DELETE_PRODUCT':
    case 'USER_UNAUTHORIZED':
      return { ...state, messages: { [action.type]: action.res }  };
    case 'CLEAR_MESSAGES':
      return { ...state, messages: {} };
    case 'REDIRECT_TO_REFERRER':
      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          redirectToReferrer: pathname,
        },
      };
    case 'UPDATE_INGREDIENT':
      state.ingredients[currentIngredient] = { ...state.ingredients[currentIngredient], ...ingredient };
      return { ...state };
    case 'UPDATE_INGREDIENTS':
      return { ...state, ingredients };
    case 'UPDATE_INGREDIENT_DETAILS':
      state.ingredients[currentIngredient] = { ...ingredient, loaded: true };
      return { ...state };
    case 'UPDATE_INGREDIENT_GROUP':
      state.ingredientGroups[currentIngredientGroup] = {
        ...state.ingredientGroups[currentIngredientGroup],
        ...ingredientGroup,
      };
      return { ...state };
    case 'UPDATE_INGREDIENT_GROUPS':
      return { ...state, ingredientGroups };
    case 'UPDATE_PRODUCT':
      state.products[currentProduct] = { ...state.products[currentProduct], ...product };
      return { ...state };
    case 'UPDATE_PRODUCTS':
      return { ...state, products };
    case 'UPDATE_PRODUCT_DETAILS':
      state.products[currentProduct] = { ...product, loaded: true };
      return { ...state };
    case 'USER_LOGGED_IN':
      return { ...state, user: { loggedIn } };
    default:
      throw new Error();
  }
};

const useAppState = () => {
  return useReducer(reducer, initialState);
};

export default useAppState;
