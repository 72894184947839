import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import AppContextProvider from './ContextProvider';
import Login from './pages/Login';
import PrivateRoute from './PrivateRoute';
import Dashboard from './pages/Dashboard';
import Products from './pages/Products';
// import AddProduct from './elements/AddProduct';
import Ingredients from './pages/Ingredients';
import IngredientGroups from './pages/IngredientGroups';

import '../static/css/layout.css';
import '../static/css/style.css';


const App = () => (
  <Router>
    <AppContextProvider>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/products/:id?" component={Products} />
      {/* <PrivateRoute exact path="/products/add" component={AddProduct} /> */}
      <PrivateRoute exact path="/ingredients/:id?" component={Ingredients} />
      <PrivateRoute exact path="/ingredient_groups/:id?" component={IngredientGroups} />
    </AppContextProvider>
  </Router>
);

export default App;
