import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../ContextProvider';
import AddProduct from '../elements/AddProduct';
import Product from '../elements/Product';
import ProductList from '../elements/ProductList';
import Message from '../elements/Message';

const Products = props => {
  const { state } = useContext(AppContext);
  const { messages, products } = state;
  const { id } = props.match.params;

  const currentProduct = products.find(p => p.product_id === id);
  const [selectedProduct, setSelectedProduct] = useState(currentProduct);

  useEffect(() => {
    setSelectedProduct(currentProduct);
  }, [id, currentProduct]);

  return (
    <div className="products">
      <div>
        <Link to="/products/add">
          <button>Add product</button>
        </Link>
        <ProductList products={products} />
      </div>

      {id === 'add'
        ? <AddProduct />
        : selectedProduct && <Product product={selectedProduct} />
      }

      {messages.DELETE_PRODUCT &&
        <Message message={<>Product successfully deleted.</>}/>
      }
    </div>
  );
};

export default Products;
