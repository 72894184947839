export default class ApiHelper {
  constructor(options) {
    this.apiURL = options.state.appSettings.apiURL;
  }

  authenticate = () => {
    return this.fetch(`${this.apiURL}/auth/login`, { method: 'GET' })
      .then(res => Promise.resolve(res))
      .catch(e => Promise.reject(e));
  };

  login = data => {
    return this.fetch(`${this.apiURL}/auth/login`, { method: 'POST', body: JSON.stringify(data) })
      .then(res => Promise.resolve(res));
  };

  logout = () => {
    return this.fetch(`${this.apiURL}/auth/logout`, { method: 'GET', headers: {} })
      .then(res => Promise.resolve(res));
  };

  addIngredient = ingredient => {
    return this.fetch(`${this.apiURL}/admin/ingredients`, { method: 'POST', body: JSON.stringify(ingredient) })
      .then(res => Promise.resolve(res));
  };

  addIngredientGroup = ingredientGroup => {
    return this.fetch(
      `${this.apiURL}/admin/ingredient_groups`,
      { method: 'POST', body: JSON.stringify(ingredientGroup) },
      )
      .then(res => Promise.resolve(res));
  };

  addProduct = product => {
    return this.fetch(`${this.apiURL}/admin/products`, { method: 'POST', body: JSON.stringify(product) })
      .then(res => Promise.resolve(res));
  };

  deleteIngredient = ingredient => {
    return this.fetch(`${this.apiURL}/admin/ingredients/${ingredient.ingredient_id}`, { method: 'DELETE' })
      .then(res => Promise.resolve(res));
  };

  deleteIngredientGroup = ingredientGroup => {
    return this
      .fetch(
        `${this.apiURL}/admin/ingredient_groups/${ingredientGroup.ingredient_group_id}`,
        { method: 'DELETE' },
      )
      .then(res => Promise.resolve(res));
  };

  deleteProduct = product => {
    return this.fetch(`${this.apiURL}/admin/products/${product.product_id}`, { method: 'DELETE' })
      .then(res => Promise.resolve(res));
  };

  updateIngredient = options => {
    const { ingredient, params } = options;
    const wrappedIngredient = options.ingredient  // @TODO ¯\_(ツ)_/¯ Check in API!
      ? ingredient
      : { ...options };
    const qs = params
      ? `?${Object.keys(params).map(i => `${i}=${params[i]}`).join('&')}`
      : '';
    const url = `${this.apiURL}/admin/ingredients${qs}`;
    return this.fetch(url, { method: 'PATCH', body: JSON.stringify(wrappedIngredient) })
      .then(res => Promise.resolve(res));
  };

  updateIngredientGroup = ingredientGroup => {
    return this.fetch(
      `${this.apiURL}/admin/ingredient_groups`,
      { method: 'PATCH', body: JSON.stringify(ingredientGroup) }
      )
      .then(res => Promise.resolve(res));
  };

  updateProduct = options => {
    const { product, params } = options;
    const wrappedProduct = options.product  // @TODO ¯\_(ツ)_/¯ Check in API!
      ? product
      : { ...options };
    const qs = params
      ? `?${Object.keys(params).map(i => `${i}=${params[i]}`).join('&')}`
      : '';
    const url = `${this.apiURL}/admin/products${qs}`;
    return this.fetch(url, { method: 'PATCH', body: JSON.stringify(wrappedProduct) })
      .then(res => Promise.resolve(res));
  };

  fetch = (url, options) => {
    const headers = options.headers || {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return fetch(url, { headers, ...options, credentials: 'include' })
      .then(this._checkStatus)
      .then(response => response.json());
  };

  _checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  };
}
