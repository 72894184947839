import React from 'react';
import { Link } from 'react-router-dom';


const IngredientGroupsList = props => {
  const { ingredientGroups } = props;

  return (
    <ul className="ingredient-groups-list">
      {ingredientGroups.map(ingredientGroup =>
        <li key={ingredientGroup.ingredient_group_id}>
          <div>
            <Link to={`/ingredient_groups/${ingredientGroup.ingredient_group_id}`}>
              {ingredientGroup.ingredient_group_name}
            </Link>
          </div>
        </li>
      )}
    </ul>
  )
};

export default IngredientGroupsList;
