import React from 'react';
import { Link } from 'react-router-dom';

import NavBar from './NavBar';


const Header = () => (
  <div className="header">
    <Link to="/">Home</Link>
    <NavBar/>
  </div>
);

export default Header;
