import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { AppContext } from '../ContextProvider';
import Message from './Message';
import RelationsManager from './RelationsManager';
import { useFormInput } from '../../helpers/Hooks';


const AddIngredient = () => {
  const { actions, state } = useContext(AppContext);
  const { ingredients, messages } = state;
  const { addIngredient } = actions;

  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedSynonyms, setSelectedSynonyms] = useState([]);

  const { value: ingredientName, bind: bindIngredientName, reset: resetIngredientName } = useFormInput(null);
  const { value: description, bind: bindDescription, reset: resetDescription } = useFormInput(null);
  const { checked: allergen, bind: bindAllergen, reset: resetAllergen } = useFormInput(false);
  const { checked: organic, bind: bindOrganic, reset: resetOrganic } = useFormInput(false);

  const handleSubmit = e => {
    e.preventDefault();
    const newIngredient = {
      ingredient_name: ingredientName,
      ingredient_is_allergen: allergen,
      ingredient_is_organic: organic,
    };
    if (description && description !== '') newIngredient.ingredient_description = description;
    if (selectedGroups && selectedGroups.length > 0) newIngredient.ingredient_groups = selectedGroups;
    if (selectedSynonyms && selectedSynonyms.length > 0) newIngredient.ingredient_synonyms = selectedSynonyms;

    addIngredient(newIngredient);
    resetIngredientName();
    resetDescription();
    resetAllergen();
    resetOrganic();
    setSelectedGroups([]);
    setSelectedSynonyms([]);
  };


  return (
    <div className="add-ingredient">
      <form onSubmit={e => handleSubmit(e)}>
        <div>
          Name:&nbsp;
          <input
            {...bindIngredientName}
            placeholder="Ingredient name"
            name="product_name"
            type="text"
            minLength={1}
          />
        </div>
        <div>
          Description:&nbsp;
          <input
            {...bindDescription}
            placeholder="Description"
            name="Description"
            type="text"
          />
        </div>
        <div>
          <label htmlFor="allergen">Allergen:</label>
          <input
            {...bindAllergen}
            id="allergen"
            name="allergen"
            type="checkbox"
            minLength={1}
          />
        </div>
        <div>
          <label htmlFor="organic">Organic: </label>
          <input
            {...bindOrganic}
            id="organic"
            name="organic"
            type="checkbox"
            minLength={1}
          />
        </div>

        <RelationsManager
          currentItem={{ ingredient_synonyms: [] }}
          searchItems="ingredients"
          searchItemKey="ingredient"
          searchItemIdentifier="ingredient_id"
          relationName="ingredient_name"
          relationChildren="ingredient_synonyms"
          relationIdentifier="ingredient_id"
          relationIdentifierOverride="ingredient_synonym_id"
          relationDescription="ingredient_description"
          currentItemsTitle="Current synonyms"
          searchLabel="Add synonym(s) to this ingredient"
          searchPlaceholder="Search ingredients"
          updateItemsFunction={items => setSelectedSynonyms(items)}
        />

        <RelationsManager
          currentItem={{ ingredient_groups: [] }}
          searchItems="ingredientGroups"
          searchItemKey="ingredient"
          searchItemIdentifier="ingredient_id"
          relationName="ingredient_group_name"
          relationChildren="ingredient_groups"
          relationIdentifier="ingredient_group_id"
          relationDescription="ingredient_group_description"
          currentItemsTitle="Add ingredient to groups"
          searchLabel="Search groups"
          updateItemsFunction={items => setSelectedGroups(items)}
        />

        <div>
          <button type="submit">Add Ingredient</button>
        </div>
      </form>

      {messages.ADD_INGREDIENT &&
        <Message
          message={
            <>
              Successfully added <Link to={`/ingredients/${messages.ADD_INGREDIENT.message}`}>
              {ingredients
                .find(i => i.ingredient_id === messages.ADD_INGREDIENT.message.toString())
                .ingredient_name
              }
            </Link>.
            </>
          }
        />
      }
    </div>
  )
};

export default AddIngredient;
