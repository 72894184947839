import React, { useContext } from 'react';

import { AppContext } from '../ContextProvider';
import EditContent from './EditContent';
import RelationsManager from './RelationsManager';


const IngredientGroup = props => {
  const { actions } = useContext(AppContext);
  const { deleteIngredientGroup, updateIngredient, updateIngredientGroup } = actions;
  const { ingredientGroup } = props;

  return (
    <div className="ingredient-group-details">
      <div>
        Name:&nbsp;
        <EditContent
          item={ingredientGroup}
          updateMethod={updateIngredientGroup}
          dbColumn="ingredient_group_name"
          identifier="ingredient_group_id"
          placeholder="Ingredient Group Name"
        />
      </div>
      <div>
        Description:&nbsp;
        <EditContent
          item={ingredientGroup}
          updateMethod={updateIngredientGroup}
          dbColumn="ingredient_group_description"
          identifier="ingredient_group_id"
          placeholder="Ingredient Group Description"
        />
      </div>

      <RelationsManager
        currentItem={ingredientGroup}
        updateFunction={updateIngredient}
        searchItems="ingredients"
        searchItemKey="ingredient"
        searchItemIdentifier="ingredient_group_id"
        relationName="ingredient_name"
        relationChildren="ingredient_groups"
        relationIdentifier="ingredient_id"
        relationDescription="ingredient_description"
        currentItemsTitle="Ingredients currently in this group"
        searchLabel="Add ingredient(s) to this group"
        searchPlaceholder="Search ingredients"
        reverseRelation={true}
      />

      <div className="metadata">
        Added by <strong>{ingredientGroup._created_by_user}</strong> ({new Date(ingredientGroup._created).toUTCString()})
        <br />
        Modified by <strong>{ingredientGroup._modified_by_user}</strong> ({new Date(ingredientGroup._modified).toUTCString()})
      </div>

      <div>
        <button onClick={() =>
          window.confirm(
            `Are you sure you want to delete ingredient group "${ingredientGroup.ingredient_group_name}"?`
          ) && deleteIngredientGroup(ingredientGroup)
        }>
          Delete ingredient group
        </button>
      </div>
    </div>
  )
};

export default IngredientGroup;
