import React from 'react';
import { Link } from 'react-router-dom';


const IngredientsList = props => {
  const { ingredients } = props;

  return (
    <ul className="ingredients-list">
      {ingredients.map(ingredient =>
        <li key={ingredient.ingredient_id}>
          <div>
            <Link to={`/ingredients/${ingredient.ingredient_id}`}>{ingredient.ingredient_name}</Link>
            {ingredient.ingredient_description ? ` (${ingredient.ingredient_description})` : ''}
          </div>
        </li>
      )}
    </ul>
  )
};

export default IngredientsList;
