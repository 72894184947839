import React, { useContext, useEffect, useState } from 'react';

import { AppContext } from '../ContextProvider';


const RelationsManager = props => {
  const { state } = useContext(AppContext);
  const {
    currentItem = {},
    currentItemsTitle = '',
    currentItemRenderer = null,
    itemRenderer = null,
    relationChildren,
    relationDescription,
    relationIdentifier,
    relationIdentifierOverride,
    relationName,
    reverseRelation = false,
    searchItems,
    searchItemIdentifier,
    searchItemKey,
    searchLabel = '',
    searchPlaceholder,
    updateFunction,
    updateItemsFunction = () => {},
  } = props;
  const allItems = state[searchItems];

  const [currentItems, setCurrentItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchResults = searchString =>
    searchString
      ? allItems.filter(i => {
          const children = reverseRelation ? i[relationChildren] : currentItem[relationChildren];
          const currentId = reverseRelation ? currentItem[searchItemIdentifier] : i[relationIdentifier];
          return i[relationName].toLowerCase().includes(searchString.toLowerCase())
            && !children.find(g =>
              parseInt(g[reverseRelation ? searchItemIdentifier : relationIdentifier]) === parseInt(currentId))
        })
      : [];

  const search = e => {
    setSearchTerm(e.target.value);
    setSearchResults(handleSearchResults(e.target.value))
  }

  const handleChange = (e, item, itemRemoved) => {
    e.preventDefault();
    if (updateFunction) {
      updateFunction({
        [searchItemKey]: reverseRelation ? item : currentItem,
        params: {
          [`${itemRemoved ? 'delete' : 'add'}:${relationIdentifierOverride || relationIdentifier}`]: reverseRelation
            ? currentItem[searchItemIdentifier]
            : item[relationIdentifier],
        },
      });
    } else {
      // We are adding a new item
      const selectedItems = itemRemoved
        ? currentItems.filter(g => parseInt(g[relationIdentifier]) !== parseInt(item[relationIdentifier]))
        : [...currentItems, item];
      setCurrentItems(selectedItems);
      updateItemsFunction(selectedItems);

      setSearchResults(
        itemRemoved
          ? [...searchResults, item]
          : searchResults.filter(g => parseInt(g[relationIdentifier]) !== parseInt(item[relationIdentifier]))
      );
    }
  }

  const changedItems = reverseRelation
    ? allItems
      .filter(i =>
        i[relationChildren].find(g => parseInt(g[searchItemIdentifier]) === parseInt(currentItem[searchItemIdentifier]))
      )
    : currentItem[relationChildren]

  useEffect(() => {
    if (updateFunction) {
      setCurrentItems(changedItems);
      setSearchResults(handleSearchResults(searchTerm));
    }
  }, [reverseRelation ? currentItem[searchItemIdentifier] : currentItem[relationChildren], allItems, searchTerm]);

  useEffect(() => {
    setSearchTerm('');
    setSearchResults([]);
    setCurrentItems(changedItems);
  }, [currentItem[searchItemIdentifier]]);

  return (
    <div className="relations-manager">
      {currentItems.length > 0 &&
        <div>
          <h5>{currentItemsTitle}</h5>
          <ul className="current-items">
            {currentItems.map(i =>
              <li key={`current-item-${i[relationIdentifier]}`}>
                {currentItemRenderer
                  ? currentItemRenderer(i)
                  : <>
                      <div className="item-name">
                        {i[relationName]}
                        {i[relationDescription] &&
                          <div className="item-description">({i[relationDescription]})</div>
                        }
                      </div>
                    </>
                }
                <span className="item-buttons">
                  <button onClick={e => handleChange(e, i, true)}>-</button>
                </span>
              </li>
            )}
          </ul>
        </div>
      }

      <div>
        {searchLabel}
        <div>
          <input
            onChange={e => search(e)}
            value={searchTerm}
            placeholder={searchPlaceholder || searchLabel}
            name="searchItem"
            type="text"
            minLength={1}
            autoComplete="off"
          />
        </div>

        {searchResults.length > 0 &&
          <div>
            <ul className="search-items">
              {searchResults
                .sort((a, b) => a[relationName].localeCompare(b[relationName]))
                .map(currentResult =>
                  <li key={`search-result-${currentResult[relationIdentifier]}`}>
                    {itemRenderer
                      ? itemRenderer(currentResult)
                      : <>
                          <div className="item-name">
                            {currentResult[relationName]}
                            {currentResult[relationDescription] &&
                              <div className="item-description">({currentResult[relationDescription]})</div>
                            }
                          </div>
                        </>
                    }
                    <div className="item-group-buttons">
                      <button onClick={e => handleChange(e, currentResult)}>+</button>
                    </div>
                  </li>
                )
              }
            </ul>
          </div>
        }
      </div>
    </div>
  )
};

export default RelationsManager;
